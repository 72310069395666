/* src/fonts.css */
@font-face {
    font-family: 'HotPizza';
    src: url('./fonts/hotpizza.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


body {
    font-family: 'CustomFont', sans-serif;
  }